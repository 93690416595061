var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"新增检测项","confirmLoading":_vm.confirmLoading,"destroyOnClose":true,"maskClosable":false},on:{"cancel":_vm.handleCancel,"ok":_vm.handleConfirm}},[_c('a-form',{attrs:{"form":_vm.dataForm,"label-col":{ span: 6 },"wrapper-col":{ span: 14 }}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                { required: true, message: '请输入名称' },
                { max: 64, message: '超出最大长度(64)' },
              ],
            },
          ]),expression:"[\n            'name',\n            {\n              rules: [\n                { required: true, message: '请输入名称' },\n                { max: 64, message: '超出最大长度(64)' },\n              ],\n            },\n          ]"}],attrs:{"allowClear":true}})],1),_c('a-form-item',{attrs:{"label":"检验类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'type',
            {
              rules: [{ required: true, message: '请选择检验类型' }],
            },
          ]),expression:"[\n            'type',\n            {\n              rules: [{ required: true, message: '请选择检验类型' }],\n            },\n          ]"}],attrs:{"allowClear":true}},[_c('a-select-option',{attrs:{"value":"dimension"}},[_vm._v("尺寸")]),_c('a-select-option',{attrs:{"value":"appearance"}},[_vm._v("外观")]),_c('a-select-option',{attrs:{"value":"weight"}},[_vm._v("重量")]),_c('a-select-option',{attrs:{"value":"performance"}},[_vm._v("性能")]),_c('a-select-option',{attrs:{"value":"composition"}},[_vm._v("成分")])],1)],1),_c('a-form-item',{attrs:{"label":"检验工具"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'tool',
            {
              rules: [{ max: 64, message: '超出最大长度(64)' }],
            },
          ]),expression:"[\n            'tool',\n            {\n              rules: [{ max: 64, message: '超出最大长度(64)' }],\n            },\n          ]"}],attrs:{"allowClear":true}})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'remark',
            {
              rules: [{ max: 256, message: '超出最大长度(256)' }],
            },
          ]),expression:"[\n            'remark',\n            {\n              rules: [{ max: 256, message: '超出最大长度(256)' }],\n            },\n          ]"}],attrs:{"allowClear":true}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }